import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import { useState, useRef, useEffect } from "react";
import PasswordToggleInput from './PasswordToggleInput';

function generateRandomPasswordWithCrypto(length) {
    const characters = '!@#$%^&*()-+<>/?;:"{[]}\\|`~abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const randomCharacters = [];
  
    while (randomCharacters.length < length) {
      const byte = crypto.getRandomValues(new Uint8Array(1))[0];
      const character = characters[byte % characters.length];
      randomCharacters.push(character);
    }
  
    return randomCharacters.join('');
}

function TextEncryption({password, changePassword, showPassword, setShowPassword}) {

    const [textAreaVal, setTextAreaVal] = useState('');
    const [textAreaResult, setTextAreaResult] = useState('');

    const [isHiddenValue, setIsHiddenValue] = useState(true);
    const [isHiddenResult, setIsHiddenResult] = useState(true);

    const bottomRef = useRef(null);
    const textAreaRef = useRef(null);

    useEffect(() => {
        if (textAreaResult)
        {
            bottomRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [textAreaResult, password, textAreaVal]);

    return (<div>
        <Row>
            <Col className={"col-12"} style={{ marginLeft: "7px" }}>
                Password:
            </Col>
        </Row>
        <Row>
            <Col className={"col-12"}>
                <PasswordToggleInput 
                    ptPasswordValue={password} 
                    ptChangePassword={(newPassword) => changePassword(newPassword)}
                    ptClass={"passwordTextBox"} 
                    ptStyle={null}
                    ptShowPassword={showPassword}
                    ptSetShowPassword={setShowPassword}
                ></PasswordToggleInput>
            </Col>
        </Row>
        <Row>
            <Col className={"col-12"} style={{ 'marginTop': '4px'}}>
                <Button 
                    onClick={() => {
                        changePassword(generateRandomPasswordWithCrypto(32));
                    }}
                    title={'auto-generate-password'}
                >Auto-generate password</Button>
            </Col>
        </Row>
        {
            password && password.length < 32 && (<Row>
                <Col>
                    <p>For best results use a 32 digit passphrase.  {32 - password.length} digit(s) left!</p>
                </Col>
            </Row>)
        }
        <Row>
            <Col>&nbsp;</Col>
        </Row>
        <Row>
            <Col style={{ marginLeft: "7px" }}>
                Paste your text to be encrypted/decrypted below.&nbsp;&nbsp;
                <label>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="hideContentCheckbox"
                        onChange={(e) => {
                            setIsHiddenValue(e.target.checked);
                        }}
                        checked={isHiddenValue}
                    /> Hide content to encrypt / decrypt
                </label>
            </Col>        
        </Row>
        <Row>
            <Col>
                <textarea
                    onChange={(e) => setTextAreaVal(e.target.value)}
                    value={textAreaVal}
                    className={`form-control textArea boxsizingBorder ${isHiddenValue ? 'hidden-textarea' : ''}`}
                    title="text-area-payload"
                    id="text-area-payload"
                    name="text-area-payload"
                ></textarea>
            </Col>
        </Row>
        <Row>
            <Col>
                <div style={{ marginBottom : "4px", marginTop: "4px" }}>
                    <button className="btn btn-success" onClick={async () => {
                            try
                            {
                                const encrypted = await window.encrypt(textAreaVal, password);
                                setTextAreaResult(encrypted);    
                            }
                            catch(error)
                            {
                                setTextAreaResult(error);
                            }
                        }} disabled={!(password && password.length >= 6 && textAreaVal)}
                    >Encrypt</button>&nbsp;
                    <button className="btn btn-danger" onClick={async () => {
                        try
                        {
                            const decrypted = await window.decrypt(textAreaVal, password)
                            setTextAreaResult(decrypted);
                        }
                        catch(error)
                        {
                            setTextAreaResult(error);
                        }
                    }} disabled={!(password && password.length >= 6 && textAreaVal)}
                    >Decrypt</button>&nbsp;&nbsp;
                    <label>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="hideResultsCheckbox"
                            onChange={(e) => {
                                setIsHiddenResult(e.target.checked);
                            }}
                            checked={isHiddenResult}
                        /> Hide encrypted / decryptrd result
                    </label>
                </div>

            </Col>
        </Row>
        <Row>
            <Col>
                <div className={"text-area-wrapper"}>
                    <textarea 
                        ref={textAreaRef} 
                        cols={50} 
                        value={textAreaResult} 
                        readOnly 
                        className={`form-control textArea boxsizingBorder ${isHiddenResult ? 'hidden-textarea' : ''}`} 
                        disabled={!textAreaResult}
                        title={'text-area-result'}
                        id={'text-area-result'}
                        name={'text-area-result'}
                    ></textarea>
                    <button
                        className={"copy-button"}
                        onClick={async () => {

                            const textArea = textAreaRef.current;
                            await navigator.clipboard.writeText(textArea.value);

                        }}
                        disabled={!textAreaResult}
                        title={'copy-results-button'}
                    >
                        <span className={"fas fa-copy"}></span>
                    </button>
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
                <br />
            </Col>
        </Row>
        <Row>
            <Col>
                <p style={{ textAlign: "justify" }}><b>Instructions:</b> First, enter a password.  If you are encrypting text paste it into the top text area, then click Encrypt.  Your encrypted text will be copyable in the lower text area.  All fields are 'hidden' by default.  Save your encrypted text, and write down your password, for decryption later.</p>
                <p style={{ textAlign: "justify" }}>Paset your encrypted text in the top text area.  Click decrypt and you will get your original text copyable in the lower text area.</p>
            </Col>
        </Row>
        <div ref={bottomRef} />
    </div>)
}

export default TextEncryption;